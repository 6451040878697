<template>
  <div 
  class="body-boxs"
  :class="$i18n.locale">
    <div class="top-boxs">
      <div class="top-stripes">
        <div class="inner-boxs">
          <div @click="language(0)"><img src="./assets/lang/cn.svg" alt="cn">中国 - 中文简体</div>
          <div @click="language(1)"><img src="./assets/lang/us.svg" alt="us">United States - English</div>
        </div>
      </div>
      <div 
      class="top-nav-boxs" 
      :class="{show:menu.status}">
        <div class="inner-boxs">
          <div class="logo">
            <div class="menu" @click="menuEvent()">
              <img :src="menu.icons" alt="">
            </div>
            <div class="name" v-text="site.title"></div>
          </div>
          <nav>
              <a 
              v-for="(items,indexs) in nav"
              :key="indexs" 
              @click="go('GO-N'+(indexs+1))"
              v-text="items.names"
              href="javascript:;"></a>
          </nav>
        </div>
      </div>
    </div>
    <div class="this-top-boxs"></div>
    <div class="body-content">
      <div class="inner-boxs">
        <div class="module-about-us" id="GO-N1">
          <div class="header-text ca">
            <div class="header" v-html="$t('language.n1.header.number')"></div>
            <div class="text">
              <h1 v-html="$t('language.n1.header.title')"></h1>
              <p v-html="$t('language.n1.header.signature')"></p>
            </div>
          </div>
          <div class="c-boxs">
            <div class="i-boxs">
              <img src="./assets/images/green-earth.jpeg" alt="">
            </div>
            <div class="t-boxs">
              <div class="boxs">
                <div v-html="$t('language.n1.m1.c1')"></div>
                <h2 v-html="$t('language.n1.m1.c2')"></h2>
                <p v-html="$t('language.n1.m1.c3')"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="module-target-tab">
          <div class="logo">
            <img src="./assets/goals.svg" alt="">
          </div>
          <div class="target">
            <div class="tab">
              <div 
              v-for="(items,indexs) in target.data" 
              :key="indexs"
              class="items"
              :class="['___' + (indexs + 1),{active:indexs === target.active.index}]" 
              @click="targetTab(indexs)">
                <div class="inner" v-if="indexs <= 16">
                  <div class="names">
                    <strong v-text="indexs + 1"></strong>
                    <span v-html="items.names"></span>
                  </div>
                  <div class="icons">
                    <img :src="items.icons" :alt="items.names">
                  </div>
                </div>
                <div class="inner" v-else>
                  <div class="icons">
                    <img :src="items.icons" :alt="items.names">
                  </div>
                </div>
              </div>
            </div>
            <div 
            class="show-boxs" 
            :class="'___'+(target.active.index + 1)">
              <div class="introduce">
                <div class="images">
                  <img :src="target.active.images" :alt="target.active.names">
                </div>
                <div class="content">
                  <div class="target-title">
                    <div class="title" v-html="target.active.names"></div>
                    <img :src="target.active.icons" :alt="target.active.names">
                  </div>
                  <p v-html="target.active.des"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="module-sdgs">
          <div class="header-sub-text">
            <div class="ai-icons">
              <div></div><div></div><div></div><div></div>
            </div>
            <div class="texts">
              <h1 v-html="$t('language.n1.m3.c1')"></h1>
              <p v-html="$t('language.n1.m3.c2')"></p>
            </div>
          </div>
          <div class="inner">
            <div class="sd-boxs">
              <div class="sdgs-l">
                <div class="sdgs-1">
                  <div class="sdgs social-solidarity" v-html="$t('language.SDGS.SocialSolidarity')"></div>
                </div>
                <div class="sdgs-2">
                  <div class="sdgs environmental-responsibility" v-html="$t('language.SDGS.EnvironmentalResponsibility')"></div>
                  <div class="sdgs economic-efficiency" v-html="$t('language.SDGS.EconomicEfficiency')"></div>
                </div>
              </div>
            </div>
            <div 
              class="target" 
              v-for="(items,indexs) in target.data"
              :key="indexs"
              :class="'___' + (indexs + 1)">
                <strong v-text="indexs + 1"></strong>
                <span v-html="items.names"></span>
            </div>
          </div>
        </div>
        <div class="module-sdgs" id="GO-N2">
          <div class="header-text cb">
            <div class="header" v-html="$t('language.n2.header.number')"></div>
            <div class="text">
              <h1 v-html="$t('language.n2.header.title')"></h1>
              <p v-html="$t('language.n2.header.signature')"></p>
            </div>
          </div>
          <div class="header-sub-text">
            <div class="ai-icons">
              <div></div><div></div><div></div><div></div>
            </div>
            <div class="texts">
              <h1 v-html="$t('language.n2.m1.header.title')"></h1>
              <p v-html="$t('language.n2.m1.header.signature')"></p>
            </div>
          </div>
          <div class="inner">
            <div class="sd-boxs">
              <div class="sdgs-l">
                <div class="sdgs-1">
                  <div class="sdgs social-solidarity" v-html="$t('language.SDGS.SocialSolidarity')"></div>
                </div>
                <div class="sdgs-2">
                  <div class="sdgs environmental-responsibility" v-html="$t('language.SDGS.EnvironmentalResponsibility')"></div>
                  <div class="sdgs economic-efficiency" v-html="$t('language.SDGS.EconomicEfficiency')"></div>
                </div>
                <div class="heptagon c-2">
                  <img src="./assets/heptagon.svg" alt="">
                </div>
              </div>
            </div>
            <div 
              class="target" 
              v-for="(items,indexs) in target.data"
              :key="indexs"
              :class="'___' + (indexs + 1)">
                <strong v-text="indexs + 1"></strong>
                <span v-html="items.names"></span>
            </div>
          </div>
          
        </div>
      </div>
      <div class="module-n2-m2">
        <div class="header">
          <div class="header-sub-text y">
            <div class="ai-icons">
              <div></div><div></div><div></div><div></div>
            </div>
            <div class="texts">
              <h1 v-html="$t('language.n2.m2.header.title')"></h1>
              <p v-html="$t('language.n2.m2.header.signature')"></p>
            </div>

            <div class="module-target-tab">
              <div class="target">
                <div class="tab">
                  <template 
                  v-for="(items,indexs) in target.data">
                    <div 
                    :key="indexs"
                    v-if="indexs == 12 || indexs == 13 || indexs == 14"
                    class="items"
                    :class="'___' + (indexs + 1)">
                      <div class="inner">
                        <div class="names">
                          <strong v-text="indexs + 1"></strong>
                          <span v-html="items.names"></span>
                        </div>
                        <div class="icons">
                          <img :src="items.icons" :alt="items.names">
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="boxs">
          <div class="lb">
            <div class="c">
              <h3 v-html="$t('language.n2.m2.c1')"></h3>
              <p v-html="$t('language.n2.m2.c2')"></p>
              <img src="./assets/images/n2-m2-1.png" alt="">
            </div>
          </div>
          <div class="rb">
            <div class="c">
              <h3 v-html="$t('language.n2.m2.c3')"></h3>
              <p v-html="$t('language.n2.m2.c4')"></p>
              <img src="./assets/images/n2-m2-2.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="inner-boxs">
        <div class="module-n2-m3">
          <div class="header">
            <div class="header-sub-text">
              <div class="ai-icons">
                <div></div><div></div><div></div><div></div>
              </div>
              <div class="texts">
                <h1 v-html="$t('language.n2.m3.header.title')"></h1>
                <p v-html="$t('language.n2.m3.header.signature')"></p>
              </div>
            </div>
            <img src="./assets/goals.svg" alt="">
          </div>
          <img src="./assets/images/maps.jpeg" alt="">
        </div>
        <div class="module-sdgs">
          <div class="header-sub-text">
            <div class="ai-icons">
              <div></div><div></div><div></div><div></div>
            </div>
            <div class="texts">
              <h1 v-html="$t('language.n2.m4.header.title')"></h1>
              <p v-html="$t('language.n2.m4.header.signature')"></p>
            </div>
          </div>
          <div class="inner">
            <div class="sd-boxs">
              <div class="sdgs-l">
                <div class="sdgs-1">
                  <div class="sdgs social-solidarity transparent" v-html="$t('language.SDGS.SocialSolidarity')"></div>
                </div>
                <div class="sdgs-2">
                  <div class="sdgs environmental-responsibility" v-html="$t('language.SDGS.EnvironmentalResponsibility')"></div>
                  <div class="sdgs economic-efficiency" v-html="$t('language.SDGS.EconomicEfficiency')"></div>
                </div>
                <div class="heptagon c-3">
                  <img src="./assets/heptagon.svg" alt="">
                </div>
              </div>
            </div>
            <div 
              class="target" 
              v-for="(items,indexs) in target.data"
              :key="indexs"
              :class="'___' + (indexs + 1)">
                <strong v-text="indexs + 1"></strong>
                <span v-html="items.names"></span>
            </div>
          </div>
        </div>
        <div class="module-n2-m5">
          <div class="goals-x">
            <img src="./assets/goals-x.svg" alt="">
          </div>
          <div class="c c1">
            <div class="text">
              <div class="content">
                <div class="header-sub-text">
                  <div class="ai-icons">
                    <div></div><div></div><div></div><div></div>
                  </div>
                  <div class="texts">
                    <h1 v-html="$t('language.n2.m5.c1.title')"></h1>
                    <p v-html="$t('language.n2.m5.c1.signature')"></p>
                  </div>
                </div>
                <p v-html="$t('language.n2.m5.c1.content')"></p>
              </div>
            </div>
            <img src="./assets/images/module-n2-m5-1.jpeg" alt="">
          </div>
          <div class="c c2">
            <img src="./assets/images/module-n2-m5-2-1.jpeg" alt="">
            <div class="text">
              <div class="content">
                <div class="header-sub-text">
                  <div class="ai-icons">
                    <div></div><div></div><div></div><div></div>
                  </div>
                  <div class="texts">
                    <h1 v-html="$t('language.n2.m5.c2.title')"></h1>
                    <p v-html="$t('language.n2.m5.c2.signature')"></p>
                  </div>
                </div>
                <p v-html="$t('language.n2.m5.c2.content')"></p>
                <div class="module-target-tab">
                  <div class="target">
                    <div class="tab">
                      <template 
                      v-for="(items,indexs) in target.data">
                        <div 
                        :key="indexs"
                        v-if="indexs == 3 || indexs == 4"
                        class="items"
                        :class="'___' + (indexs + 1)">
                          <div class="inner">
                            <div class="names">
                              <strong v-text="indexs + 1"></strong>
                              <span v-html="items.names"></span>
                            </div>
                            <div class="icons">
                              <img :src="items.icons" :alt="items.names">
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="module-n3-m1" id="GO-N3">
          <div class="header-text cc">
            <div class="header" v-html="$t('language.n3.header.number')"></div>
            <div class="text">
              <h1 v-html="$t('language.n3.header.title')"></h1>
              <p v-html="$t('language.n3.header.signature')"></p>
            </div>
          </div>
          <div class="header">
            <div class="header-sub-text">
              <div class="ai-icons">
                <div></div><div></div><div></div><div></div>
              </div>
              <div class="texts">
                <h1 v-html="$t('language.n3.m1.header.title')"></h1>
                <p v-html="$t('language.n3.m1.header.signature')"></p>
              </div>
            </div>
            <img src="./assets/grid-logo.png" alt="">
          </div>
          <div class="boxs">
            <div class="s a">
              <b v-html="$t('language.n3.m1.c1.title')"></b>
              <ol>
                <li v-html="$t('language.n3.m1.c1.l1')"></li>
                <li v-html="$t('language.n3.m1.c1.l2')"></li>
              </ol>
            </div>
            <div class="s b">
              <b v-html="$t('language.n3.m1.c2.title')"></b>
              <ol>
                <li v-html="$t('language.n3.m1.c2.l1')"></li>
                <li v-html="$t('language.n3.m1.c2.l2')"></li>
                <li v-html="$t('language.n3.m1.c2.l3')"></li>
              </ol>
            </div>
            <div class="s c">
              <b v-html="$t('language.n3.m1.c3.title')"></b>
              <ol>
                <li v-html="$t('language.n3.m1.c3.l1')"></li>
                <li v-html="$t('language.n3.m1.c3.l2')"></li>
                <li v-html="$t('language.n3.m1.c3.l3')"></li>
              </ol>
            </div>
          </div>
        </div>
        <div class="module-n3-m2-3">
          <div class="header-sub-text">
            <div class="ai-icons">
              <div></div><div></div><div></div><div></div>
            </div>
            <div class="texts">
              <h1 v-html="$t('language.n3.m2.header.title')"></h1>
              <p v-html="$t('language.n3.m2.header.signature')"></p>
            </div>
          </div>
          <div class="boxs">
            <div class="m m2">
              <div class="s a">
                <div v-html="$t('language.n3.m2.c1.number')"></div>
                <b v-html="$t('language.n3.m2.c1.title')"></b>
                <ol>
                  <li v-html="$t('language.n3.m2.c1.l1')"></li>
                  <li v-html="$t('language.n3.m2.c1.l2')"></li>
                </ol>
              </div>
              <div class="s b">
                <div v-html="$t('language.n3.m2.c2.number')"></div>
                  <b v-html="$t('language.n3.m2.c2.title')"></b>
                  <ol>
                    <li v-html="$t('language.n3.m2.c2.l1')"></li>
                    <li v-html="$t('language.n3.m2.c2.l2')"></li>
                  </ol>
              </div>
              <div class="s c">
                <div v-html="$t('language.n3.m2.c3.number')"></div>
                <b v-html="$t('language.n3.m2.c3.title')"></b>
                <ol>
                  <li v-html="$t('language.n3.m2.c3.l1')"></li>
                  <li v-html="$t('language.n3.m2.c3.l2')"></li>
                  <li v-html="$t('language.n3.m2.c3.l3')"></li>
                </ol>
              </div>
              <div class="s d">
                <div v-html="$t('language.n3.m2.c4.number')"></div>
                <b v-html="$t('language.n3.m2.c3.title')"></b>
                <ol>
                  <li v-html="$t('language.n3.m2.c4.l1')"></li>
                  <li v-html="$t('language.n3.m2.c4.l2')"></li>
                  <li v-html="$t('language.n3.m2.c4.l3')"></li>
                </ol>
              </div>
            </div>
            <div class="m m3">
              <div class="header-sub-text">
                <div class="ai-icons">
                  <div></div><div></div><div></div><div></div>
                </div>
                <div class="texts">
                  <h1 v-html="$t('language.n3.m3.header.title')"></h1>
                  <p v-html="$t('language.n3.m3.header.signature')"></p>
                </div>
              </div>
              <ol>
                <li v-html="$t('language.n3.m3.c1')"></li>
                <li v-html="$t('language.n3.m3.c2')"></li>
                <li v-html="$t('language.n3.m3.c3')"></li>
              </ol>
              <div class="line"></div>
              <div class="header-sub-text">
                <div class="ai-icons">
                  <div></div><div></div><div></div><div></div>
                </div>
                <div class="texts">
                  <h1 v-html="$t('language.n3.m4.header.title')"></h1>
                  <p v-html="$t('language.n3.m4.header.signature')"></p>
                </div>
              </div>
              <p v-html="$t('language.n3.m4.c1')"></p>
              <ul>
                <li>{{$t('language.n3.m4.c2')}}：GoodLuckforAll999</li>
                <li>{{$t('language.n3.m4.c3')}}：1641177303@qq.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      site: {
        title: 'PSG Alliance',
        des: 'First inner meeting'  
      },
      menu: {
        status: false,
        icons: require(`@/assets/icons/menu.svg`)
      },
      nav: [],
      target: {
        active: {
          index: 0,
          names: '',
          des: ''
        },
        data: []
      }
    }
  },
  created () {
    this.nav = this.$i18n.messages[this.$i18n.locale].language.nav;
    this.target.data = this.$i18n.messages[this.$i18n.locale].language.target;
    for (let index = 0; index < this.target.data.length; index++) {
      this.target.data[index].images = require(`@/assets/goal/${index + 1}.jpeg`);
      this.target.data[index].icons = require(`@/assets/goal/${index + 1}.svg`);
    }
    this.targetTab(this.target.active.index);
  },
  methods: {
    language(type) {
      
      if (type == 0) {
        localStorage.setItem('language', 'zh')
        this.$i18n.locale = 'zh'
      } else if (type == 1) {
        localStorage.setItem('language', 'en')
        this.$i18n.locale = 'en'
      }
      this.target.data = this.$i18n.messages[this.$i18n.locale].language.target;
      for (let index = 0; index < this.target.data.length; index++) {
        this.target.data[index].images = require(`@/assets/goal/${index + 1}.jpeg`);
        this.target.data[index].icons = require(`@/assets/goal/${index + 1}.svg`);
      }
      this.targetTab(0);
      this.nav = this.$i18n.messages[this.$i18n.locale].language.nav;
    },
    targetTab(indexs){
      this.target.active.index = indexs;
      this.target.data[this.target.active.index].index = this.target.active.index;
      this.target.active = this.target.data[this.target.active.index];
    },
    menuEvent(){
      this.menu.status = this.menu.status === false;

      if (this.menu.status) {
        this.menu.icons = require(`@/assets/icons/hide.svg`);
      }else{
        this.menu.icons = require(`@/assets/icons/menu.svg`);
      }
    },
    go(id){
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
      this.menu.status = true;
      this.menuEvent();
    }
  }
}
</script>
