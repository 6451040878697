export default {
    language: {
        n1: {
          header: {
            number: "壹",
            title: 'ORIGIN<br>的起源',
            signature: '它是什么?有什么想法'
          },
          m1: {
            c1: '关于我们',
            c2: '什么是PSG，我们要做什么?',
            c3: '促进可持续发展目标(PSG)联盟是一个非营利性组织，于2020年在中国北京成立。它旨在推广绿色地球、我们的家园的理念，并落实联合国17项可持续发展目标。成立了一个公益工会'
          },
          m3: {
            c1: '可持续发展目标和可持续发展的维度',
            c2: '制度层面'
          }
        },
        n2: {
          header: {
            number: '贰',
            title: '现有<br>活动',
            signature: '护城河行动，儿童看护中心…'
          },
          m1: {
            header: {
              title: '可持续发展目标和可持续发展的维度',
              signature: '制度层面'
            }
          },
          m2: {
            header: {
              title: '护城河活动——我们为什么要这么做?',
              signature: '环境污染'
            },
            c1: '调查显示',
            c2: `根据调查，土地退化影响到全世界15亿人，74%的世界贫困人口直接受到土地退化的影响。而土地退化则是由于土地的不当使用和过度使用，化学物质的积累影响了植物和农作物的生长。同时，也给空气和水带来了严重的污染。`,
            c3: '我们正在行动',
            c4: `我们在河边捡垃圾，让环境、土壤、河流变得更干净、卫生、健康。我们在河边普及知识，让更多的人意识到环境保护的重要性并加入进来。`
          },
          m3: {
            header: {
              title: '护城河活动',
              signature: '加入我们'
            }
          },
          m4: {
            header: {
              title: '儿童关爱中心项目——社会团结',
              signature: '制度层面'
            }
          },
          m5: {
            c1: {
              title: '儿童姑息治疗中心在哪里',
              signature: '他们的使命是为患有复杂、难以治疗疾病的儿童提供额外的支持。',
              content: `提高生命受到限制、生命受到威胁或情况复杂的儿童的生活质量<br><br>姑息治疗的重点是帮助有非常复杂健康问题的儿童。在Children 's Health，姑息治疗团队帮助在医院和门诊就诊的儿童。他们的使命是为患有复杂、难以治疗疾病的儿童提供额外的支持。`
            },
            c2: {
              title: '儿童关怀项目我们做什么?',
              signature: '举办小活动',
              content: `我们每月会为孩子们举办一次小活动，比如绘画、音乐会、英语故事讲解等。这样学生就可以有更多的时间去准备，让孩子们感受到社会的温暖，感受到哥哥姐姐们的热情<br><br>( 八年级上 )`
            }
          }
        },
        n3: {
          header: {
            number: '叁',
            title: '未来 <br>的讨论',
            signature: '我们的愿景和使命，欢迎大家的加入'
          },
          m1: {
            header: {
              title: '初步的未来愿景',
              signature: '初步计划'
            },
            c1: {
              title: '区域',
              l1: '2019年北京开幕',
              l2: '超过100人参加活动'
            },
            c2: {
              title: '国家',
              l1: '城市的代表',
              l2: '国家的网站',
              l3: '扩大影响圈'
            },
            c3: {
              title: '全球',
              l1: '全球提交',
              l2: '创意共享',
              l3: '活动经验'
            }
          },
          m2: {
            header: {
              title: '主席团以及城市代表招募',
              signature: '分四步'
            },
            c1: {
              title: '宣传部 VP',
              number: '壹',
              l1: '负责各大社交媒体推广',
              l2: '擅长剪辑 ，手绘， 排版等'
            },
            c2: {
              title: '学术部 VP',
              number: '贰',
              l1: '优质 Ideas 提出， 有能力可论文编写',
              l2: '部分推文撰写输出'
            },
            c3: {
              title: '城市代表 VP',
              number: '叁',
              l1: '带领商讨城市内部活动',
              l2: '参加全国代表会议开展联动可能',
              l3: '肯定实施是最重要的啦～'
            },
            c4: {
              title: '学校/区域代表 CM',
              number: '肆',
              l1: '探讨校内区域活动发展潜能',
              l2: '城市内部活动讨论与联动',
              l3: '实施是最重要的啦～'
            }
          },
          m3: {
            header: {
              title: '初步讨论',
              signature: 'Discussion'
            },
            c1: '职位分工',
            c2: '自我介绍',
            c3: '未来预期'
          },
          m4: {
            header: {
              title: '招募联系人',
              signature: '招募中'
            },
            c1: `目前大部分活动在北京中国举行。如果您有兴趣参加活动，请直接通过电子邮件/微信（1641177303#qq.com/13606920512）与若云女士联系。或者，如果您愿意成为PSG的核心成员，以及领导活动的地区或城市代表。我们很乐意与您讨论细节。`,
            c2: '微信',
            c3: '邮箱'
          }
        },
        nav: [{
          names: 'PSG的起源',
        },{
          names: '现有活动',
        },{
          names: '未来的讨论',
        }],
        target: [{
            names: '无<br>贫穷',
            des: `消除各地一切形式的贫穷<br><br><ol>
            <li>1.1、到2030年，为所有地方的所有人消除极端贫穷，目前标准按照每天生活费不足1.25美元计算。</li>
            <li>1.2、到2030年，所有年龄层的男女老少中按照国家定义的贫困人的比例，至少减少一半。</li>
            <li>1.3、对所有的人，包括底层的人，实施适合国家的社会保护制度措施，到了公元2030年，范围涵盖贫穷与弱势族群。</li>
            <li>1.4、在公元2030年前，确保所有的男男女女，尤其是贫穷与弱势族群，在经济资源、基本服务、以及土地与其他形式的财产、继承、天然资源、新科技与财务服务（包括微型贷款）都有公平的权利与取得权。</li>
            <li>1.5、在公元 2030 年前，让贫穷与弱势族群具有灾后复原能力，减少他们暴露于气候极端事件与其他社经与环境灾害的频率与受伤害的严重度。</li>
            <li>1.A、确保从各种来源中大量动员，包括增强发展合作、为发展中国家提供适当且可预测的方法，特别是最不发达国家（LDCs），以实施计划与政策来终结各种形式的贫穷。</li>
            <li>1.B、依据考量到贫穷与两性的发展策略，建立国家的、区域的与国际层级的妥善政策架构，加速消除贫穷行动。</li>
            </ol>`
          },{
            names: '零<br>饥饿',
            des: `消除饥饿，达成粮食安全，改善营养及促进永续农业<br><br><ol>
            <li>2.1、在公元2030年前，消除饥饿，确保所有的人，尤其是贫穷与弱势族群（包括婴儿），都能够终年取得安全、营养且足够的粮食。</li>
            <li>2.2、在公元2030年前，消除所有形式的营养不良，包括在公元2025年前，达成国际合意的五岁以下儿童，并且解决青少女、孕妇、哺乳妇女以及老年人的营养需求。</li>
            <li>2.3、在公元2030年前，使农村的生产力与小规模粮食生产者的收入增加一倍，尤其是妇女、原住民、家族式农夫、牧民与渔夫，包括让他们有安全及公平的土地、生产资源、知识、财务服务、市场、增值机会以及非农业就业机会的管道。</li>
            <li>2.4、在公元2030年前，确保可可持续发展的粮食生产系统，并实施可灾后复原的农村作法，提高产能及生产力，协助维护生态系统，强化适应气候变迁、极端气候、干旱、洪水与其他灾害的能力，并渐进改善土地与土壤的品质。</li>
            <li>2.5、在公元2020年前，维持种子、栽种植物、家畜以及与他们有关的野生品种之基因多样性，包括善用国家、国际与区域妥善管理及多样化的种籽与植物银行，并确保运用基因资源与有关传统知识所产生的好处得以依照国际协议而公平的分享。</li>
            <li>2.A、提高在乡村基础建设、农村研究、扩大服务、科技发展、植物与家畜基因银行上的投资，包括透过更好的国际合作，以改善发展中国家的农业产能，尤其是最落后国家。</li>
            <li>2.B、矫正及预防全球农业市场的交易限制与扭曲，包括依据杜哈发展圆桌，同时消除各种形式的农业出口补助及产生同样影响的出口措施。</li>
            <li>2.C、采取措施，以确保食品与他们的衍生产品的商业市场发挥正常的功能，并如期取得市场资讯，包括储粮，以减少极端的粮食价格波动。</li>
            </ol>`
          },{
            names: '良好<br>健康与福祉',
            des: `确保健康及促进各年龄层的福祉<br><br><ol>
            <li>3.1、在公元2030年前，减少全球的死产率，让每100,000个活产的死胎数少于70个。</li>
            <li>3.2、在公元2030年前，消除可预防的新生儿以及五岁以下儿童的死亡率。</li>
            <li>3.3、在公元2030年前，消除艾滋病、肺结核、疟疾以及受到忽略的热带性疾病，并对抗肝炎，水传染性疾病以及其他传染疾病。</li>
            <li>3.4、在公元2030年前，透过预防与治疗，将非传染性疾病的未成年死亡数减少三分之一，并促进心理健康。</li>
            <li>3.5、强化物质滥用的预防与治疗，包括麻醉药品滥用以及酗酒。</li>
            <li>3.6、在公元2020年前，让全球因为交通事故而伤亡的人数减少一半。</li>
            <li>3.7、在公元2030年前，确保全球都有管道可取得性与生殖医疗保健服务，包括家庭规划、资讯与教育，并将生殖医疗保健纳入国家策略与计划之中。</li>
            <li>3.8、实现医疗保健涵盖全球（以下简称 UHC）的目标，包括财务风险保护，取得高品质基本医疗保健服务的管道，以及所有的人都可取得安全、有效、高品质、负担得起的基本药物与疫苗。</li>
            <li>3.9、在公元2030年以前，大幅减少死于危险化学物质、空气 污染、水污染、土壤污染以及其他污染的死亡及疾病人数。</li>
            <li>3.A、强化烟草管制架构公约在所有国家的实施与落实。</li>
            <li>3.B、对主要影响发展中国家的传染以及非传染性疾病，支援疫苗以及医药的研发，依据杜哈宣言提供负担的起的基本药物与疫苗；杜哈宣言确认发展中国家有权利使用国际专利规范-与贸易有关之知识产权协定（以下简称TRIPS）中的所有供应品，以保护民众健康，尤其是必须提供医药管道给所有的人。</li>
            <li>3.C、大幅增加发展中国家的医疗保健的融资与借款，以及医疗保健从业人员的招募、培训以及留任，尤其是LDCs与SIDS。</li>
            <li>3.D、强化所有国家的早期预警、风险减少，以及国家与全球健康风险的管理能力，特别是发展中国家。</li>
            </ol>`
          },{
            names: '优质<br>教育',
            des: `确保有教无类、公平以及高品质的教育，及提倡终身学<br><br><ol>
            <li>4.1、在公元2030年以前，确保所有的男女学子都完成免费的、公平的以及高品质的小学与中学教育，得到有关且有效的学习成果。</li>
            <li>4.2、在公元2030年以前，确保所有的孩童都能接受高品质的早期幼儿教育、照护，以及小学前教育，因而为小学的入学作好准备。</li>
            <li>4.3、在公元2030年以前，确保所有的男女都有公平、负担得起、高品质的技职、职业与高等教育的受教机会，包括大学。</li>
            <li>4.4、在公元2030年以前，将拥有相关就业、觅得好工作与企业管理职能的年轻人与成人的人数增加x%，包括技术与职业技能。</li>
            <li>4.5、在公元2030年以前，消除教育上的两性不平等，确保弱势族群有接受各阶级教育的管道与职业训练，包括身心障碍者、原住民以及弱势孩童。</li>
            <li>4.6、在公元2030年以前，确保所有的年轻人以及至少x%的 成人，不管男女，都具备读写以及算术能力。</li>
            <li>4.7、在公元2030年以前，确保所有的学子都习得必要的知识与技能而可以促进可持续发展，包括可持续发展教育、永续生活模式、人权、性别平等、和平及非暴力提倡、全球公民、文化差异欣赏，以及文化对可持续发展的贡献。</li>
            <li>4.A、建立及提升适合孩童、身心障碍者以及两性的教育设施，并为所有的人提供安全的、非暴力的、有教无类的、以及有效的学习环境。</li>
            <li>4.B、在公元2020年以前，将全球发展中国家的奖学金数目增加x%，尤其是LDCs、SIDS与非洲国家，以提高高等教育的受教率，包括发达国家与其他发展中国家的职业训练、资讯与通信科技（以下简称ICT），技术的、工程的，以及科学课程。</li>
            <li>4.C、在公元2030年以前，将合格师资人数增加x%，包括在发展中国家进行国际师资培训合作，尤其是LDCs与SIDS。</li>
            </ol>`
          },{
            names: '性别<br>平等',
            des: `实现男女平等，并赋予妇女权力<br><br><ol>
            <li>5.1、消除所有地方对妇女的各种形式的歧视。</li>
            <li>5.2、消除公开及私人场合中对妇女的各种形式的暴力，包括人口走私、性侵犯，以及其他各种形式的剥削。</li>
            <li>5.3、消除各种有害的做法，例如童婚、未成年结婚、强迫结婚，以及女性生殖器切割。</li>
            <li>5.4、透过提供公共服务、基础建设与社会保护政策承认及重视妇女无给职的家庭照护与家事操劳，依据国情，提倡家事由家人共同分担。</li>
            <li>5.5、确保妇女全面参与政经与公共决策，确保妇女有公平的机会参与各个阶层的决策领导。</li>
            <li>5.6、依据国际人口与发展会议（以下简称 ICPD）行动计划、北京行动平台，以及它们的检讨成果书，确保每个地方的人都有管道取得性与生殖医疗照护服务。</li>
            <li>5.A、进行改革，以提供妇女公平的经济资源权利，以及土地与其他形式的财产、财务服务、继承与天然资源的所有权与掌控权。</li>
            <li>5.B、改善科技的使用能力，特别是ICT，以提高妇女的能力。</li>
            <li>5.C、采用及强化完善的政策以及可实行的立法，以促进两性平等，并提高各个阶层妇女的能力。</li>
            </ol>`
          },{
            names: '清洁饮水<br>和卫生设施',
            des: `确保所有人都能享有水及卫生及其永续管理<br><br><ol>
            <li>6.1、在公元2030年以前，让全球的每一个人都有公平的管道，可以取得安全且负担的起的饮用水。</li>
            <li>6.2、在公元2030年以前，让每一个人都享有公平及妥善的卫生，终结露天大小便，特别注意弱势族群中妇女的需求。</li>
            <li>6.3、在公元2030年以前，改善水质，减少污染，消除垃圾倾倒，减少有毒物化学物质与危险材料的释出，将未经处理的废水比例减少一半，将全球的回收与安全再使用率提高x%。</li>
            <li>6.4、在公元2030年以前，大幅增加各个产业的水使用效率，确保永续的淡水供应与回收，以解决水饥荒问题，并大幅减少因为水计划而受苦的人数。</li>
            <li>6.5、在公元2030年以前，全面实施一体化的水资源管理，包括跨界合作。</li>
            <li>6.6、在公元2020年以前，保护及恢复跟水有关的生态系统，包括山脉、森林、沼泽、河流、含水层，以及湖泊。</li>
            <li>6.A、在公元2030年以前，针对发展中国家的水与卫生有关活动与计划，扩大国际合作与能力培养支援，包括采水、去盐、水效率、废水处理、回收，以及再使用科技。</li>
            <li>6.B、支援及强化地方社区的参与，以改善水与卫生的管理。</li>
            </ol>`
          },{
            names: '经济适用的<br>清洁能源',
            des: `确保所有的人都可取得负担得起、可靠的、永续的，及现代的能源<br><br><ol>
            <li>7.1、在公元2030年前，确保所有的人都可取得负担的起、可靠的，以及现代的能源服务。</li>
            <li>7.2、在公元2030年以前，大幅提高全球再生能源的共享。</li>
            <li>7.3、在公元2030年以前，将全球能源效率的改善度提高一倍。</li>
            <li>7.A、在公元2030年以前，改善国际合作，以提高干净能源与科技的取得管道，包括再生能源、能源效率、更先进及更干净的石化燃料科技，并促进能源基础建设与干净能源科技的投资。</li>
            <li>7.B、在公元2030年以前，扩大基础建设并改善科技，以为所有发展中国家提供现代及永续的能源服务，尤其是LDCs与SIDS。</li>
            </ol>`
          },{
            names: '体面工作<br>和经济增长',
            des: `促进包容且永续的经济成长，达到全面且有生产力的就业，让每一个人都有一份好工作<br><br><ol>
            <li>8、促进包容且永续的经济成长，达到全面且生产力的就业，让每一个人都有一份好工作	8.1、依据国情维持经济成长，尤其是开发度最低的国家，每年的国内生产毛额（以下简称GDP）成长率至少7%。</li>
            <li>8.2、透过多元化、科技升级与创新提高经济体的产能，包括将焦点集中在高附加价值与劳动力密集的产业。</li>
            <li>8.3、促进以开发为导向的政策，支援生产活动、就业创造、企业管理、创意与创新，并鼓励微型与中小企业的正式化与成长，包括取得财务服务的管道。</li>
            <li>8.4、在公元2030年以前，渐进改善全球的能源使用与生产效率，在发达国家的带领下，依据十年的永续使用与生产计划架构，努力减少经济成长与环境恶化之间的关联。</li>
            <li>8.5、在公元2030年以前，实现全面有生产力的就业，让所有的男女都有一份好工作，包括年轻人与身心障碍者，并实现同工同酬的待遇。</li>
            <li>8.6、在公元2020年以前，大幅减少失业、失学或未接受训练的年轻人。</li>
            <li>8.7、采取立即且有效的措施，以禁止与消除最糟形式的童工，消除受压迫的劳工；在公元2025年以前，终结各种形式的童工，包括童兵的招募使用。</li>
            <li>8.8、保护劳工的权益，促进工作环境的安全，包括迁徙性劳工，尤其是妇女以及实行危险工作的劳工。</li>
            <li>8.9、在公元2030年以前，制定及实施政策，以促进可持续发展的观光业，创造就业，促进地方文化与产品。</li>
            <li>8.10、强化本国金融机构的能力，为所有的人提供更宽广的银行、保险与金融服务。</li>
            <li>8.A、提高给发展中国家的贸易协助资源，尤其是LDCs，包括为LDCs提供更好的整合架构。</li>
            <li>8.B、在公元2020年以前，制定及实施年轻人就业全球策略，并落实全球劳工组织的全球就业协定。</li>
            </ol>`
          },{
            names: '产业、创新<br>和基础设施',
            des: `建立具有韧性的基础建设，促进包容且永续的产业发展，并加速创新<br><br><ol>
            <li>9、建立具有韧性的基础建设，促进包容且永续的工业，并加速创新	9.1、发展高品质的、可靠的、永续的，以及具有灾后复原能力的基础设施，包括区域以及跨界基础设施，以支援经济发展和人类福祉，并将焦点放在为所有的人提供负担的起又公平的管道。</li>
            <li>9.2、促进包容以及永续的工业化，在公元2030年以前，依照各国的情况大幅提高工业的就业率与GDP，尤其是LDCs应增加一倍。</li>
            <li>9.3、提高小规模工商业取得金融服务的管道，尤其是发展中国家，包括负担的起的贷款，并将他们并入价值链与市场之中。</li>
            <li>9.4、在公元2030年以前，升级基础设施，改造工商业，使他们可可持续发展，提高能源使用效率，大幅采用干净又环保的科技与工业制程，所有的国家都应依据他们各自的能力行动。</li>
            <li>9.5、改善科学研究，提高所有国家的工商业的科技能力，尤其是发展中国家，包括在公元2030年以前，鼓励创新，并提高研发人员数，每百万人增加x%，并提高公民营的研发支出。</li>
            <li>9.A、透过改善给非洲国家、LDCs、内陆发展中国家（以下简称LLDCs）与SIDS 的财务、科技与技术支援，加速发展中国家发展具有灾后复原能力且永续的基础设施。</li>
            <li>9.B、支援发展中国家的本国科技研发与创新，包括打造有助工商多元发展以及商品附加价值提升的政策环境。</li>
            <li>9.C、大幅提高ICT的管道，在公元2020年以前，在开发度最低的发展中国家致力提供人人都可取得且负担的起的互联网管道。</li>
            </ol>`
          },{
            names: '减少<br>不平等',
            des: `减少国内及国家间不平等<br><br><ol>
            <li>10、减少国内及国家间不平等	10.1、在公元2030年以前，以高于国家平均值的速率渐进地致使底层百分之40的人口实现所得成长。</li>
            <li>10.2、在公元2030年以前，促进社经政治的融合，无论年龄、性别、身心障碍、种族、人种、祖国、宗教、经济或其他身份地位。</li>
            <li>10.3、确保机会平等，减少不平等，作法包括消除歧视的法律、政策及实务作法，并促进适当的立法、政策与行动。</li>
            <li>10.4、采用适当的政策，尤其是财政、薪资与社会保护政策，并渐进实现进一步的平等。</li>
            <li>10.5、改善全球金融市场与金融机构的法规与监管，并强化这类法规的实施。</li>
            <li>10.6、提高发展中国家在全球经济与金融机构中的决策发言权，以实现更有效、更可靠、更负责以及更正当的机构。</li>
            <li>10.7、促进有秩序的、安全的、规律的，以及负责的移民，作法包括实施规划及管理良好的移民政策。</li>
            <li>10.A、依据世界贸易组织的协定，对发展中国家实施特别且差异对待的原则，尤其是开发度最低的国家。</li>
            <li>10.B、依据国家计划与方案，鼓励官方开发援助（以下简称ODA）与资金流向最需要的国家，包括外资直接投资，尤其是LDCs、非洲国家、SIDS、以及LLDCs。</li>
            <li>10.C、在公元2030年以前，将迁移者的汇款手续费减少到小于3%，并消除手续费高于5%的汇款。</li>
            </ol>`
          },{
            names: '可持续<br>城市和社区',
            des: `促使城市与人类居住具包容、安全、韧性及永续性<br><br><ol>
            <li>11、促使城市与人类居住具包容、安全、韧性及永续性	11.1、在公元2030年前，确保所有的人都可取得适当的、安全的，以及负担的起的住宅与基本服务，并改善贫民窟。</li>
            <li>11.2、在公元2030年以前，为所有的人提供安全的、负担的起、可使用的，以及可可持续发展的交通运输系统，改善道路安全，尤其是扩大公共运输，特别注意弱势族群、妇女、儿童、身心障碍者以及老年人的需求。</li>
            <li>11.3、在公元2030年以前，提高融合的、包容的以及可可持续发展的都市化与容积，以让所有的国家落实参与性、一体性以及可可持续发展的人类定居规划与管理。</li>
            <li>11.4、在全球的文化与自然遗产的保护上，进一步努力。</li>
            <li>11.5、在公元2030年以前，大幅减少灾害的死亡数以及受影响的人数，并将灾害所造成的GDP经济损失减少y%，包括跟水有关的伤害，并将焦点放在保护弱势族群与贫穷者。</li>
            <li>11.6、在公元2030年以前，减少都市对环境的有害影响，其中包括特别注意空气质量、都市管理与废弃物管理。</li>
            <li>11.7、在公元2030年以前，为所有的人提供安全的、包容的、可使用的绿色公共空间，尤其是妇女、孩童、老年人以及身心障碍者。</li>
            <li>11.A、强化国家与区域的发展规划，促进都市、郊区与城乡之间的社经与环境的正面连结。</li>
            <li>11.B、在公元2020年以前，致使在包容、融合、资源效率、移民、气候变迁适应、灾后复原能力上落实一体政策与 计划的都市与地点数目增加x%，依照日本兵库县架构管理所有阶层的灾害风险。</li>
            <li>11.C、支援开发度最低的国家，以妥善使用当地的建材，营建具有灾后复原能力且可永续的建筑，作法包括财务与技术上的协助。</li>
            </ol>`
          },{
            names: '负责<br>任消费和生产',
            des: `确保永续消费及生产模式<br><br><ol>
            <li>12、确保永续的消费与生产模式</li>
            <li>12.1、实施永续消费与生产十年计划架构（以下简称10YEP），所有的国家动起来，由发达国家担任带头角色，考量发展中国家的发展与能力。</li>
            <li>12.2、在公元2030年以前，实现自然资源的永续管理以及有效率的使用。</li>
            <li>12.3、在公元2030年以前，将零售与消费者阶层上的全球粮食浪费减少一半，并减少生产与供应链上的粮食损失，包括采收后的损失。</li>
            <li>12.4、在公元2020年以前，依据议定的国际架构，在化学药品与废弃物的生命周期中，以符合环保的方式妥善管理化学药品与废弃物，大幅减少他们释放到空气、水与土壤中，以减少他们对人类健康与环境的不利影响。</li>
            <li>12.5、在公元2030年以前，透过预防、减量、回收与再使用大幅减少废弃物的产生。</li>
            <li>12.6、鼓励企业采取可可持续发展的工商作法，尤其是大规模与跨国公司，并将永续性资讯纳入他们的报告周期中。</li>
            <li>12.7、依据国家政策与优先要务，促进可可持续发展的公共采购流程。</li>
            <li>12.8、在公元2030年以前，确保每个地方的人都有可持续发展的有关资讯与意识，以及跟大自然和谐共处的生活方式。</li>
            <li>12.A、协助发展中国家强健它们的科学与科技能力，朝向更能可持续发展的耗用与生产模式。</li>
            <li>12.B、制定及实施政策，以监测可持续发展对创造就业，促进地 方文化与产品的永续观光的影响。</li>
            <li>12.C、依据国情消除市场扭曲，改革鼓励浪费的无效率石化燃料补助，作法包括改变课税架构，逐步废除这些有害的补助，以反映他们对环境的影响，全盘思考发展中国家的需求与状况，以可以保护贫穷与受影响社区的方式减少它们对发展的可能影响。</li>
            </ol>`
          },{
            names: '气候<br>行动',
            des: `采取紧急措施以因应气候变迁及其影响<br><br><ol>
            <li>13、采取紧急措施以因应气候变迁及其影响	13.1、强化所有国家对天灾与气候有关风险的灾后复原能力与调适适应能力。</li>
            <li>13.2、将气候变迁措施纳入国家政策、策略与规划之中。</li>
            <li>13.3、在气候变迁的减险、适应、影响减少与早期预警上，改善教育，提升意识，增进人与机构的能力。</li>
            <li>13.A、在公元2020年以前，落实UNFCCC发达国家签约国的承诺，目标是每年从各个来源募得美元1千亿，以有意义的减灾与透明方式解决发展中国家的需求，并尽快让绿色气候基金透过资本化而全盘进入运作。</li>
            <li>13.B、提升开发度最低国家中的有关机制，以提高能力而进行有效的气候变迁规划与管理，包括将焦点放在妇女、年轻人、地方社区与边缘化社区。</li>
            </ol>`
          },{
            names: '水下<br>生物',
            des: `保育及永续利用海洋与海洋资源，以确保可持续发展<br><br><ol>
            <li>14、保育及永续利用海洋与海洋资源，以确保可持续发展	14.1、在公元2025年以前，预防及大幅减少各式各样的海洋污染，尤其是来自陆上活动的污染，包括海洋废弃物以及营养污染。</li>
            <li>14.2、在公元2020年以前，以可永续的方式管理及保护海洋与海岸生态，避免重大的不利影响，作法包括强健他们的灾后复原能力，并采取复原动作，以实现健康又具有生产力的海洋。
            <li>14.3、减少并解决海洋酸化的影响，作法包括改善所有阶层的科学合作。
            <li>14.4、在公元2020年以前，有效监管采收，消除过度渔捞，以及非法的、未报告的、未受监管的（以下简称IUU）、或毁灭性鱼捞作法，并实施科学管理计划，在最短的时间内，将鱼量恢复到依据它们的生物特性可产生最大可持续发展的鱼量。
            <li>14.5、在公元2020年以前，依照国家与国际法规，以及可取得的最佳科学资讯，保护至少10%的海岸与海洋区。
            <li>14.6、在公元2020年以前，禁止会造成过度鱼捞的补助，消除会助长IUU鱼捞的补助，禁止引入这类补助，承认对发展中国家与开发度最低国家采取适当且有效的特别与差别待遇应是世界贸易组织渔捞补助协定的一部分。
            <li>14.7、在公元2030年以前，提高海洋资源永续使用对SIDS与LDCs的经济好处，作法包括永续管理渔捞业、水产养殖业与观光业。
            <li>14.A、提高科学知识，发展研究能力，转移海洋科技，思考跨政府海洋委员会的海洋科技转移准则，以改善海洋的健康，促进海洋生物多样性对发展中国家的发展贡献，特别是SIDS与LDCs。
            <li>14.B、提供小规模人工鱼捞业者取得海洋资源与进入市场的管道。
            <li>14.C、确保联合国海洋法公约（以下简称UNCCLOS）签约国全面落实国际法，包括现有的区域与国际制度，以保护及永续使用海洋及海洋资源。
            </ol>`
          },{
            names: '陆地<br>生物',
            des: `保护、维护及促进陆域生态系统的永续使用，永续的管理森林，对抗沙漠化，终止及逆转土地劣化，并遏止生物多样性的丧失<br><br><ol>
            <li>15、保护、维护及促进领地生态系统的永续使用，永续的管理森林，对抗沙漠化，终止及逆转土地劣化，并遏止生物多样性的丧失	15.1、在公元2020年以前，依照在国际协定下的义务，保护、恢复及永续使用领地与内陆淡水生态系统与他们的服务，尤其是森林、沼泽、山脉与旱地。</li>
            <li>15.2、在公元2020年以前，进一步落实各式森林的永续管理，终止毁林，恢复遭到破坏的森林，并让全球的造林增加x%。</li>
            <li>15.3、在公元2020年以前，对抗沙漠化，恢复恶化的土地与土壤，包括受到沙漠化、干旱及洪水影响的地区，致力实现没有土地破坏的世界。</li>
            <li>15.4、在公元2030年以前，落实山脉生态系统的保护，包括他们的生物多样性，以改善他们提供有关可持续发展的有益能力。</li>
            <li>15.5、采取紧急且重要的行动减少自然栖息地的破坏，终止生物多样性的丧失，在公元2020年以前，保护及预防濒危物种的绝种。</li>
            <li>15.6、确保基因资源使用所产生的好处得到公平公正的分享，促进基因资源使用的适当管道。</li>
            <li>15.7、采取紧急动作终止受保护动植物遭到盗采、盗猎与非法走私，并解决非法野生生物产品的供需。</li>
            <li>15.8、在公元2020年以前，采取措施以避免侵入型外来物种入侵陆地与水生态系统，且应大幅减少他们的影响，并控管或消除物种。</li>
            <li>15.9、在公元2020年以前，将生态系统与生物多样性价值纳入国家与地方规划、发展流程与脱贫策略中。</li>
            <li>15.A、动员并大幅增加来自各个地方的财物资源，以保护及永续使用生物多样性与生态系统。</li>
            <li>15.B、大幅动员来自各个地方的各阶层的资源，以用于永续森林管理，并提供适当的奖励给发展中国家改善永续森林管理，包括保护及造林。</li>
            <li>15.C、改善全球资源，以对抗保护物种的盗采、盗猎与走私，作法包括提高地方社区的能力，以追求可持续发展的谋生机会。</li>
            </ol>`
          },{
            names: '和平、正义<br>与强大机构',
            des: `促进和平且包容的社会，以落实可持续发展；提供司法管道给所有人；在所有阶层建立有效的、负责的且包容的制度<br><br><ol>
            <li>16、促进和平且包容的社会，以落实可持续发展；提供司法管道给所有人；在所有的阶层建立有效的、负责的且包容的制度	16.1、大幅减少各地各种形式的暴力以及有关的死亡率。</li>
            <li>16.2、终结各种形式的儿童虐待、剥削、走私、暴力以及施虐。</li>
            <li>16.3、促进国家与国际的法则，确保每个人都有公平的司法管道。</li>
            <li>16.4、在公元2030年以前，大幅减少非法的金钱与军火流，提高失物的追回，并对抗各种形式的组织犯罪。</li>
            <li>16.5、大幅减少各种形式的贪污贿赂。</li>
            <li>16.6、在所有的阶层发展有效的、负责的且透明的制度。</li>
            <li>16.7、确保各个阶层的决策回应民意，是包容的、参与的且具有代表性。</li>
            <li>16.8、扩大及强化发展中国家参与全球管理制度。</li>
            <li>16.9、在公元2030年以前，为所有的人提供合法的身份，包括出生登记。</li>
            <li>16.10、依据国家立法与国际协定，确保民众可取得资讯，并保护基本自由。</li>
            <li>16.A、强化有关国家制度，作法包括透过国际合作，以建立在各个阶层的能力，尤其是发展中国家，以预防暴力并对抗恐怖主义与犯罪。</li>
            <li>16.B、促进及落实没有歧视的法律与政策，以实现可持续发展。</li>
            </ol>`
          },{
            names: '促进目标<br>实现的伙伴关系',
            des: `强化可持续发展执行方法及活化可持续发展全球伙伴关系<br><br>财务：<br><br><ol>
            <li>17.1、强化本国的资源动员，作法包括提供国际支援给发展中国家，以改善他们的税收与其他收益取得的能力。</li>
            <li>17.2、发达国家全面落实他们的ODA承诺，包括在ODA中提供国民所得毛额（以下简称GNI）的0.7%给发展中国家，其中0.15-0.20%应提供该给LDCs。</li>
            <li>17.3、从多个来源动员其他财务支援给发展中国家。</li>
            <li>17.4、透过协调政策协助发展中国家取得长期负债清偿能力，目标放在提高负债融资、负债的解除，以及负责的重整，并解决高负债贫穷国家（以下简称HIPC）的外部负债，以减少负债压力。</li>
            <li>17.5、为LDCs采用及实施投资促进方案。</li>
            </ol><br><br>技术：<br><br><ol>
            <li>17.6、在科学、科技与创新上，提高北半球与南半球、南半球与南半球，以及三角形区域性与国际合作，并使用公认的词语提高知识交流，作法包括改善现有机制之间的协调，尤其是联合国水平，以及透过合意的全球科技促进机制。</li>
            <li>17.7、使用有利的条款与条件，包括特许权与优惠条款，针对 发展中国家促进环保科技的发展转移、流通及扩散。</li>
            <li>17.8、在公元2017年以前，为LDCs全面启动科技银行以及科学、科技与创新（以下简称STI）能力培养机制，并提高科技的使用度，尤其是ICT。</li>
            </ol><br><br>能力建置：<br><br><ol>
            <li>17.9、提高国际支援，以在发展中国家实施有效且锁定目标的能力培养，以支援国家计划，落实所有的可持续发展目标，作法包括北半球国家与南半球国家、南半球国家与南半球国家，以及三角合作。</li>
            </ol><br><br>贸易：<br><br><ol>
            <li>17.10、在世界贸易组织（以下简称WTO）的架构内，促进全球的、遵循规则的、开放的、没有歧视的，以及公平的多边贸易系统，作法包括在杜哈发展议程内签署协定。</li>
            <li>17.11、大幅增加发展中国家的出口，尤其是在公元 2020 年以前，让 LDCs 的全球出口占比增加一倍。</li>
            <li>17.12、对所有 LDCs，依照 WTO 的决定，如期实施持续性免关税、没有配额的市场进入管道，包括适用 LDCs 进口的原产地优惠规则必须是透明且简单的，有助市场进入。</li>
            </ol><br><br><b>制度议题</b><br><br>政策与制度连贯：<br><br><ol>
            <li>17.13、提高全球总体经济的稳定性，作法包括政策协调与政策连贯。</li>
            <li>17.14、提高政策的连贯性，以实现可持续发展。</li>
            <li>17.15、尊敬每个国家的政策空间与领导，以建立及落实消除贫穷与可持续发展的政策。</li>
            </ol><br><br>多边合作：<br><br><ol>
            <li>17.16、透过多边合作辅助并提高全球在可持续发展上的合作，动员及分享知识、专业、科技与财务支援，以协助所有国家实现可持续发展目标，尤其是发展中国家。</li>
            <li>17.17、依据合作经验与资源策略，鼓励及促进有效的公民营以及公民社会的合作。</li>
            </ol>
            <br><br>资料、监督及责任：<br><br><ol>
            <li>17.18、在公元2020年以前，提高对发展中国家的能力培养协助，包括LDCs与SIDS以大幅提高收入、性别、年龄、种族、人种、移民身份、身心障碍、地理位置，以及其他有关特色的高品质且可靠的资料数据的如期取得性。</li>
            <li>17.19、在公元2030年以前，依据现有的方案评量跟GDP有关的可持续发展的进展，并协助发展中国家的统计能力培养。</li>
            </ol>`
        },{
            names: '可持续发展目标',
            des: `可持续发展目标（英语：Sustainable Development Goals，简称SDGs）是联合国的一系列目标，这些目标于2015年底替换千年发展目标。这些目标将从2016年一直持续到2030年。这一系列目标共有169项细项目标。<br><br>可持续发展目标于2016年开始在全球范围内导入，此过程称为“可持续发展目标的在地化”。地球上的所有事物、人种、大学、政府、机构和组织，都共同致力于多个目标。各国政府必须积极寻求合作伙伴，同时将目标纳入国家法律体系并将其立法，制定执行计划与订定预算。此外，低度发展国家需要高度发展国家的支持，因此国际间的协调极为重要。<br><br>变革世界的17个目标<br><br>可持续发展目标呼吁所有国家（不论该国是贫穷、富裕还是中等收入）行动起来，在促进经济繁荣的同时保护地球。目标指出，消除贫困必须与一系列战略齐头并进，包括促进经济增长，解决教育、卫生、社会保护和就业机会的社会需求，遏制气候变化和保护环境。`
        }],
        SDGS: {
          SocialSolidarity: '社会团结',
          EnvironmentalResponsibility: '环境责任',
          EconomicEfficiency: '经济效率'
        }
    }
}